import React, { FC } from 'react';
import PropTypes from 'prop-types';
// import CollegeLogo from '../assets/logos/logo.jpg';

interface ILogoProps {
	width?: number;
	height?: number;
}
const Logo: FC<ILogoProps> = ({ width, height }) => {
	return (
		<img src={'https://school.bluebottless.com/assets/logos/logo.jpg'}
		// <img src={'http://localhost/omniwheels/bb-school-web/src/assets/logos/logo.jpg'}
			// width={160} height={160}
			width={width}
			height={height}
		/>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;
