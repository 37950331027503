import { useEffect, useState, useRef, useContext } from "react";
import { useReactToPrint } from "react-to-print";
import useDarkMode from "../../../hooks/useDarkMode";
import { toasts } from "../../../services/toast.service";
import Button from "../../bootstrap/Button";
import Page from "../../../layout/Page/Page";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import FormGroup from "../../bootstrap/forms/FormGroup";
import AlertService from "../../../services/AlertService";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import { getAcademicPeriod } from "../../../services/master.service";
import AuthContext from "../../../contexts/authContext";
import { getStandardDetails } from "../../../services/common.service";
import SearchableSelect from "../../../common/components/SearchableSelect";
import { getExamDetails, getExamTimeTable } from "../../../services/exam.service";
import OpenCardComponent from "../../../common/components/OpenCardComponent";
import Collapse from "../../bootstrap/Collapse";
import classNames from "classnames";
import { getProfileForStudent } from "../../../services/student.service";
import Icon from "../../icon/Icon";

function ExamDetails() {

    useEffect(() => {
        getAcademicPeriodList()
        getStandardList()
        if (userTypeId == 9) {
            getStudentProfile(userAccountId)
        }
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [examTimeTableData, setExamTimeTableData] = useState<any>([]);
    const componentRef = useRef(null);

    const [academicPeriodId, setAcademicPeriodId] = useState<any>('')
    const [academicPeriodData, setAcademicPeriodData] = useState<any>([])
    const [examDetailsId, setExamDetailsId] = useState<any>('');
    const [examTypeData, setExamTypeData] = useState<any>([]);

    const [standardDetailsId, setStandardDetailsId] = useState<any>('')
    const [standardData, setStandardData] = useState<any>([])

    const [studentDetailsData, setStudentDetailsData] = useState<any>([])
    const [studentAcademic, setStudentAcademic] = useState<any>('')
    const [studentStandard, setStudentStandard] = useState<any>('')

    const [dataSuccess, setDataSuccess] = useState(false)
    const [isLoader, setIsLoader] = useState(false)
    const [isOpenListCard, setIsOpenListCard] = useState(true)

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('')

    const selectAcademicPeriod = (e: any) => {
        setExamDetailsId('')
        setStandardDetailsId('')
        let academicPeriodId = e
        setAcademicPeriodId(academicPeriodId)
        if (academicPeriodId?.value != undefined && userTypeId != 9) {
            getExam(academicPeriodId?.value)
        }
    }

    const selectExamType = (e: any) => {
        setStandardDetailsId('')
        let examDetailsId = e
        setExamDetailsId(examDetailsId)

    }

    const selectStandard = (e: any) => {
        let standardDetailsId = e
        setStandardDetailsId(standardDetailsId)
    }

    function getStandardList() {
        getStandardDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.standardDetails;
                    if (data != undefined) {
                        setStandardData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setStandardData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getAcademicPeriodList() {
        getAcademicPeriod(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.academicPeriod;
                    if (data != undefined) {
                        setAcademicPeriodData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setAcademicPeriodData([]);
                    //toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getExam(academicPeriodId: any) {
        getExamDetails(academicPeriodId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.examDetails;
                    if (data != undefined) {
                        setExamTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setExamTypeData([]);
                    //toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentProfile(studentDetailsId: any) {
        getProfileForStudent(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentProfile;
                    if (data != undefined) {
                        setStudentDetailsData(data);
                        setStudentAcademic(data[0].academicPeriodId)
                        setStudentStandard(data[0].standardDetailsId)
                        getExam(data[0].academicPeriodId)
                    } else {
                        toasts("Undefined Data", "Error")
                        setDataSuccess(false)
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                    setDataSuccess(false)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setDataSuccess(false)
                }
            }, error => {
                toasts(error, "Error")
                setDataSuccess(false)
            }
        )
    }

    const [exam, setExam] = useState<any>('')
    const [standard, setStandard] = useState<any>('')

    function getExamTimeTableList(academicPeriodId: any, examDetailsId: any, standardDetailsId: any) {
        setIsLoader(true);
        getExamTimeTable(
            academicPeriodId, examDetailsId, standardDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.examTimeTable;
                    if (data != undefined) {
                        setIsLoader(false);
                        setExamTimeTableData(data);
                        setExam(data[0].examTypeName)
                        setStandard(data[0].standardName)
                        setIsOpenListCard(false);
                        setDataSuccess(true);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setIsLoader(false);
                    //toasts(response.data.message, "Error")
                    setExamTimeTableData([]);
                    setDataSuccess(true);
                    setIsOpenListCard(false);
                    setNoDataMsg(response.data.message);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                setIsLoader(false);
                toasts(error, 'Error');
            },
        );
    }

    function viewExamList() {
        setStandard('');
        setExam('');
        setIsLoader(true);
        setDataSuccess(false);
        setExamTimeTableData([]);
        if (userTypeId == 9) {
            getExamTimeTableList(studentAcademic, examDetailsId?.value, studentStandard)
        }
        else {
            getExamTimeTableList(academicPeriodId?.value, examDetailsId?.value, standardDetailsId?.value)
        }
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <PageWrapper title={`Exam List`}>
                <Page container='fluid'>

                    <Collapse isOpen={isOpenListCard}>
                        <Card stretch data-tour='list'>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>
                                        Exam List
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                {userTypeId == 9 ?
                                    <div className="row">
                                        <div className=" col-md-3">
                                            <FormGroup id='examDetailsId' label='Exam Type' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Exam Type' placeholder="Exam Type"
                                                    value={examDetailsId}
                                                    onChange={selectExamType}
                                                    list={examTypeData.map((data: any) => (
                                                        { value: data.examDetailsId, label: data.examTypeName }
                                                    ))} required />
                                            </FormGroup>
                                        </div>

                                        {examDetailsId ?
                                            <div className='col-md-3 align-self-center'>
                                                <Button icon='ArrowDownward' color='primary' onClick={viewExamList}>View</Button>
                                            </div>
                                            : null}
                                    </div>
                                    :
                                    <div className="row g-4">
                                        <div className='col-md-3'>
                                            <FormGroup id='academicPeriodId' label='Academic Period' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Academic Period' placeholder="Academic Period"
                                                    value={academicPeriodId}
                                                    onChange={selectAcademicPeriod}
                                                    list={academicPeriodData.map((data: any) => (
                                                        { value: data.academicPeriodId, label: data.period }
                                                    ))} required />
                                            </FormGroup>
                                        </div>
                                        {academicPeriodId ?
                                            <div className=" col-md-3">
                                                <FormGroup id='examDetailsId' label='Exam Type' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Exam Type' placeholder="Exam Type"
                                                        value={examDetailsId}
                                                        onChange={selectExamType}
                                                        list={examTypeData.map((data: any) => (
                                                            { value: data.examDetailsId, label: data.examTypeName }
                                                        ))} required />
                                                </FormGroup>
                                            </div> : null}

                                        {examDetailsId ?
                                            <div className='col-md-3'>
                                                <FormGroup id='standardDetailsId' label='Standard' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Standard' placeholder="Standard"
                                                        onChange={selectStandard}
                                                        value={standardDetailsId}
                                                        list={standardData.map((data: any) => ({ value: data.standardDetailsId, label: data.standardName }))} required />
                                                </FormGroup>
                                            </div> : null}

                                        {standardDetailsId ?
                                            <div className='col-md-3 align-self-center'>
                                                <Button icon='ArrowDownward' color='primary' onClick={viewExamList}>View</Button>
                                            </div>
                                            : null}
                                    </div>
                                }
                            </CardBody>
                        </Card>
                    </Collapse>

                    <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                    {dataSuccess ? (
                        <>
                            {examTimeTableData != '' ? (
                                <>
                                    <table className="table table-modern table-hover text-center text-nowrap text-md-nowrap mg-b-0 mt-2">
                                        <thead>
                                            <tr style={{ textAlign: 'center' }} className='table-primary'>
                                                <td colSpan={13}
                                                    style={{ fontFamily: 'Bookman Old Style', color: 'rgb(61, 57, 57)' }}>
                                                    <h5> {exam} Exam Time Table For Standard -  {standard}</h5>
                                                </td>
                                            </tr>
                                        </thead>
                                    </table>

                                    <div className='col-12 mt-3' >
                                        <div className='row'>
                                            {examTimeTableData.map((exam: any) => (
                                                <div className='col-3' key={exam.examTimeTableId} >
                                                    <Card stretch shadow={'md'} className="border border-light">
                                                        <CardBody>
                                                            <div className='row g-3 align-items-center'>
                                                                <div className='col d-flex align-items-center'>
                                                                    <div className='flex-shrink-0'>
                                                                        <div
                                                                            className='ratio ratio-1x1'
                                                                            style={{ width: 65 }}>
                                                                            <div
                                                                                className={classNames(
                                                                                    'rounded-2',
                                                                                    'd-flex align-items-center justify-content-center',
                                                                                    {
                                                                                        'bg-l10-info':
                                                                                            !darkModeStatus,
                                                                                        'bg-lo25-info':
                                                                                            darkModeStatus,
                                                                                    },
                                                                                )}>
                                                                                <span className='fw-bold'>
                                                                                    <span className='ms-1 text-info fs-2'>
                                                                                        {(exam.dateOfExamForView).substring(0, 2)}
                                                                                    </span><br />
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex-grow-1 ms-3 d-flex justify-content-between align-items-center'>
                                                                        <div>
                                                                            <small className='fw-bold fs-5'>
                                                                                {exam.paperName}
                                                                            </small>
                                                                            <div className=' text-info fs-6 mb-0'>
                                                                                {exam.dateOfExamForView} ({(exam.examSession)})
                                                                            </div>
                                                                            <div className='text-info'>
                                                                                <small>
                                                                                    <span
                                                                                        className={classNames(
                                                                                            'fw-bold',
                                                                                            {
                                                                                                'text-black-50':
                                                                                                    !darkModeStatus,
                                                                                                'text-white-50':
                                                                                                    darkModeStatus,
                                                                                            },
                                                                                        )}>
                                                                                        {exam.startTime} - {exam.endTime}
                                                                                    </span>
                                                                                </small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className='row'>
                                    <div className='col-12' style={{ height: '16vh' }}></div>
                                    <div className='col-12 d-flex justify-content-center'>
                                        <Icon icon='DoNotDisturb' size={'10x'} />
                                    </div>
                                    <div className='fs-4 fw-bold text-center text-uppercase text-danger'>
                                        Time Table Not Created
                                    </div>
                                </div>
                            )}
                        </>
                    ) : null}
                </Page>

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </PageWrapper>
        </>
    )
} export default ExamDetails;